
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  watch,
  onMounted,
  computed,
} from "vue";
import useStaffRepositories from "@/_composables/useStaffRepositories";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
export default defineComponent({
  props: {
    staffId: {
      type: Number,
      require: true,
    },
  },

  setup(props) {
    const store = useStore();
    const { t } = useI18n({
      useScope: "global",
    });

    const state = reactive({
      currentPageHornorNation: 1,
      currentPageHornorClub: 1,
      currentPageHornorPersonal: 1,
      pageSizeOptionsHornorNation: ["10", "20", "50", "100"],
      nation: [],
      columnsNation: {
        th: [
          {
            title: "ชื่อรายการแข่งขัน",
            dataIndex: "name",
            key: "name",
            align: "center",
          },
          {
            title: "ทีมชาติชุด",
            dataIndex: "team",
            key: "team",
          },
          {
            title: "ชื่อรางวัล",
            dataIndex: "award",
            key: "award",
          },
          {
            title: "ปีที่ได้รับ",
            dataIndex: "year",
            key: "year",
          },
        ],
        en: [
          {
            title: "Name of Competition",
            dataIndex: "name",
            key: "name",
            align: "center",
          },
          {
            title: "Team Name",
            dataIndex: "team",
            key: "team",
          },
          {
            title: "Award name",
            dataIndex: "award",
            key: "award",
          },
          {
            title: "Year",
            dataIndex: "year",
            key: "year",
          },
        ],
      },

      columnsClub: {
        th: [
          {
            title: "ชื่อรายการแข่งขัน",
            dataIndex: "name",
            key: "name",
            align: "center",
          },
          {
            title: "สโมสร",
            dataIndex: "team",
            key: "team",
          },
          {
            title: "ชื่อรางวัล",
            dataIndex: "award",
            key: "award",
          },
          {
            title: "ปีที่ได้รับ",
            dataIndex: "year",
            key: "year",
          },
        ],
        en: [
          {
            title: "Name of Competition",
            dataIndex: "name",
            key: "name",
            align: "center",
          },
          {
            title: "Team Name",
            dataIndex: "team",
            key: "team",
          },
          {
            title: "Award name",
            dataIndex: "award",
            key: "award",
          },
          {
            title: "Year",
            dataIndex: "year",
            key: "year",
          },
        ],
      },
      columnsPersonal: {
        th: [
          {
            title: "ชื่อรายการแข่งขัน",
            dataIndex: "name",
            key: "name",
            align: "center",
          },
          {
            title: "ทีมชาติ/สโมสร",
            dataIndex: "team",
            key: "team",
          },
          {
            title: "ชื่อรางวัล",
            dataIndex: "award",
            key: "award",
          },
          {
            title: "ปีที่ได้รับ",
            dataIndex: "year",
            key: "year",
          },
        ],
        en: [
          {
            title: "Name of Competition",
            dataIndex: "name",
            key: "name",
            align: "center",
          },
          {
            title: "Team Name",
            dataIndex: "team",
            key: "team",
          },
          {
            title: "Award name",
            dataIndex: "award",
            key: "award",
          },
          {
            title: "Year",
            dataIndex: "year",
            key: "year",
          },
        ],
      },
      dataSourceNation: [],
      dataSourceClub: [],
      dataSourcePersonal: [],
      loading: false,
      hornorNation: {},
      hornorClub: {},
      hornorPersonal: {},
    });
    const { getHonorNation, getHonorClub, getHonorPersonal } =
      useStaffRepositories();

    const { staffId } = props;

    onMounted(async () => {
      try {
        await fetchHonorNation({
          size: state.pageSizeOptionsHornorNation[0],
          page: state.currentPageHornorNation,
        });

        await fetchHonorClub({
          size: state.pageSizeOptionsHornorNation[0],
          page: state.currentPageHornorClub,
        });

        await fetchHonorPersonal({
          size: state.pageSizeOptionsHornorNation[0],
          page: state.currentPageHornorPersonal,
        });
      } catch (error) {
      } finally {
        state.loading = false;
      }
    });
    const fetchHonorNation = async (params: any) => {
      if (!staffId) {
        return;
      }
      const result = await getHonorNation(staffId, params);
      const { data, ...others } = result.nation;
      const nation = result.nation.data.map((ele: any, index: number) => {
        return {
          key: "" + (index + 1),
          name: ele[store.state.currentLanguage]["honorName"],
          award: ele[store.state.currentLanguage]["competitionName"],
          team: ele[store.state.currentLanguage]["teamName"],
          year: ele.honorYear,
        };
      });
      state.hornorNation = {
        ...others,
        data: nation,
      };
    };
    const fetchHonorClub = async (params: any) => {
      if (!staffId) {
        return;
      }
      const result = await getHonorClub(staffId, params);
      const { data, ...others } = result.nation;

      const club = result.nation.data.map((ele: any, index: number) => {
        return {
          key: "" + (index + 1),
          name: ele[store.state.currentLanguage]["honorName"],
          award: ele[store.state.currentLanguage]["competitionName"],
          team: ele[store.state.currentLanguage]["teamName"],
          year: ele.honorYear,
        };
      });
      state.hornorClub = {
        ...others,
        data: club,
      };
    };
    const onPageChangeHornorNation = (page: any, pageSize: any) => {
      state.currentPageHornorNation = page;
      fetchHonorNation({ page, size: pageSize });
    };
    const onShowSizeChangeHornorNation = (current: any, pageSize: any) => {
      state.currentPageHornorNation = current;
      fetchHonorNation({ page: current, size: pageSize });
    };
    const onPageChangeHornorClub = (page: any, pageSize: any) => {
      state.currentPageHornorClub = page;
      fetchHonorClub({ page, size: pageSize });
      // onFetchHornorClub({ page, size: pageSize });
    };
    const onShowSizeChangeHornorClub = (current: any, pageSize: any) => {
      state.currentPageHornorClub = current;
      fetchHonorClub({ page: current, size: pageSize });
    };
    const onPageChangeHornorPersonal = (page: any, pageSize: any) => {
      state.currentPageHornorPersonal = page;
      fetchHonorPersonal({ page, size: pageSize });
    };
    const onShowSizeChangeHornorPersonal = (current: any, pageSize: any) => {
      state.currentPageHornorPersonal = current;
      fetchHonorPersonal({ page: current, size: pageSize });
    };

    const fetchHonorPersonal = async (params: any) => {
      if (!staffId) {
        return;
      }
      const result = await getHonorPersonal(staffId, params);
      const { data, ...others } = result.nation;

      const personal = result.nation.data.map((ele: any, index: number) => {
        return {
          key: "" + (index + 1),
          name: ele[store.state.currentLanguage]["honorName"],
          award: ele[store.state.currentLanguage]["competitionName"],
          team: ele[store.state.currentLanguage]["teamName"],
          year: ele.honorYear,
        };
      });
      state.hornorPersonal = {
        ...others,
        data: personal,
      };
    };
    computed(() => {
      console.log("store", store);
    });
    watch(
      () => store.state.currentLanguage,
      (newValue) => {
        fetchHonorNation({
          page: state.currentPageHornorNation,
          size: state.pageSizeOptionsHornorNation[0],
        });
        fetchHonorClub({
          page: state.currentPageHornorClub,
          size: state.pageSizeOptionsHornorNation[0],
        });
        fetchHonorPersonal({
          page: state.currentPageHornorPersonal,
          size: state.pageSizeOptionsHornorNation[0],
        });
      }
    );
    return {

      t,
      store,
      ...toRefs(state),
      onPageChangeHornorNation,
      onShowSizeChangeHornorNation,
      onPageChangeHornorClub,
      onShowSizeChangeHornorClub,
      onPageChangeHornorPersonal,
      onShowSizeChangeHornorPersonal,
    };
  },
});
