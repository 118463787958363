
import { defineComponent, reactive, toRefs, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { format } from "date-fns";
import { th } from "date-fns/locale";
import addYears from "date-fns/addYears";
import { useRequest } from "vue-request";
import SatIcon from "@/components/SatIcon.vue";
import SatCardCarousel from "@/components/carousel/SatCardCarousel.vue";
import { CalendarOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import SatBreadcrumb from "@/components/SatBreadcrumb.vue";
import { athleteService } from "@/_services";
import {
  Athlete,
  BaseResponse,
  BreadcrumbRoute,
  IAthlete,
} from "@/_modules/types";
import Tab1 from "./Tab1.vue";
import Tab2 from "./Tab2.vue";
import Tab3 from "./Tab3.vue";
import Tab4 from "./Tab4.vue";
import Tab5 from "./Tab5.vue";
import Tab6 from "./Tab6.vue";
import { useStore } from "vuex";
export default defineComponent({
  name: "Athlete",
  components: {
    SatIcon,
    SatCardCarousel,
    SatBreadcrumb,
    CalendarOutlined,
    LoadingOutlined,
    Tab1,
    Tab2,
    Tab3,
    Tab4,
    Tab5,
    Tab6,
  },
  setup() {
    const store = useStore();
    const formatAthlete = (result: BaseResponse<Athlete>) => {
      const { data } = result;
      const {
        account_informations,
        account_injuries,
        global_config_positions,
        team_club,
        team_national,
        global_config_physical_fitness: fitness,
        technical_skills,
      } = data;
      state.account_injuries = account_injuries;
      const {
        account_media_preview_url,
        account_information_firstname_th: firstNameTh,
        account_information_lastname_th: lastNameTh,
        account_information_firstname_en: firstName,
        account_information_lastname_en: lastName,
        account_information_age: age,
        account_information_biography_th: bioTh,
        account_information_biography_en: bioEn,
        account_information_dob: dob,
        account_information_nationality: nationality,
        account_information_nationality_en: nationalityTh,
        account_information_height: height,
        account_information_weight: weight,
      } = account_informations;
      // state.firstTab.content = bio ?? "";
      state.firstTab.content = {
        en: bioEn ?? "",
        th: bioTh ?? "",
      };
      state.firstTab.info.forEach((e, idx, arr) => {
        const { key } = e;
        // console.log("firstTab :: ", e, idx, arr);
        switch (key) {
          case "age":
            if (age) {
              arr[idx].value = {
                th: `${age} ปี`,
                en: `${age} years`,
              };
            }

            break;
          case "dob":
            if (dob) {
              arr[idx].value = {
                th: format(addYears(new Date(dob), 543), "dd MMMM yyyy", {
                  locale: th,
                }),
                en: format(new Date(dob), "dd MMMM yyyy"),
              };
            }

            break;
          case "nationality":
            arr[idx].value = {
              th: nationality,
              en: nationalityTh,
            };
            break;
          case "height":
            arr[idx].value = {
              th: height ? `${height} ซม.` : "-",
              en: height ? `${height} cm.` : "-",
            };
            break;
          case "weight":
            arr[idx].value = {
              th: weight ? `${weight} กก.` : "-",
              en: weight ? `${weight} kg.` : "-",
            };
            break;
        }
      });

      return {
        name: {
          th: firstNameTh ? `${firstNameTh} ${lastNameTh}` : "-",
          en: firstName ? `${firstName} ${lastName}` : "-",
        },
        avatar: account_media_preview_url ?? "",
        positions: global_config_positions.map((ele) => {
          const {
            global_config_position_abbreviation: abbr,
            global_config_position_name_th: name_th,
            global_config_position_name_en: name_en,
          } = ele;
          const tmp = {} as { [key: string]: any };
          tmp[abbr] = {
            en: name_en,
            th: name_th,
          };
          return tmp;
        }),
        age,
        team: {
          // club: team_club?.setting_team_information_name_th,
          club: {
            en: team_club?.setting_team_information_name_en ?? "-",
            th: team_club?.setting_team_information_name_th ?? "-",
          },
          // national: team_national?.setting_team_information_name_th,
          national: {
            en: team_national?.setting_team_information_name_en ?? "-",
            th: team_national?.setting_team_information_name_th ?? "-",
          },
          logo: {
            club: team_club?.setting_team_media_profile_url,
            national: team_national?.setting_team_media_profile_url,
          },
        },
        fitness: {
          percentage: fitness?.global_config_physical_fitness_percentage ?? -1,
          suggestion: {
            en: fitness?.global_config_physical_fitness_name_en ?? "-",
            th: fitness?.global_config_physical_fitness_name_th ?? "-",
          },
          // suggestion: fitness?.global_config_physical_fitness_name_th ?? "-",
        },
        technical_skills,
      } as IAthlete;
    };
    const { run: fetchAthlete } = useRequest(athleteService.getBanner, {
      manual: true,
      formatResult: formatAthlete,
      onSuccess: (formattedResult) => {
        state.athlete = { ...state.athlete, ...formattedResult };
        if (formattedResult.technical_skills) {
          let skill = state.abilities as any;
          skill = formattedResult.technical_skills.map((ts, index) => {
            return {
              // name: state.abilities[index].name,
              name: {
                th: ts.global_technical_skill_id.global_config_skill_name_th,
                en: ts.global_technical_skill_id.global_config_skill_name_en,
              },
              percentage: +ts.account_technical_skill_percentage,
            };
          });
          state.abilities = skill;
        }
        const found = routes.value.findIndex((r) => !r.path);
        if (found > -1) {
          routes.value[found].breadcrumbName = state.athlete.name.th;
        }
      },
    });
    const { t } = useI18n({
      useScope: "global",
    });
    const route = useRoute();

    watch(
      () => route.params.id,
      async (newId) => {
        if (Number.isNaN(+newId)) return;
        await fetchAthlete(+newId);
        state.loading = false;
      },
      { immediate: true }
    );
    const state = reactive({
      loading: true,
      athlete: {
        id: +route.params.id,
        age: 20,
        name: { en: "Chanathip Songkrasin", th: "ชนาธิป สรงกระสินธ์" },
        positions: [
          { LW: "ปีกซ้าย" },
          { CF: "กองหน้า" },
          { AMC: "กลางตัวรุก" },
        ],
        team: { club: { en: "", th: "" }, national: { en: "", th: "" } },
        nationallity: {
          th: "",
          en: "",
        },
        avatar: "",
        fitness: {
          percentage: 50,
          suggestion: {
            en: "",
            th: "",
          },
        },
        teamsId: [],
        birthday: "",
        nationality: "",
        weight: "",
        height: "",
      } as IAthlete,
      abilities: [
        { name: "ความแม่นยำในการทำประตู", percentage: 66 },
        { name: "การจ่ายบอล", percentage: 74 },
        { name: "ฟอร์มการเล่น", percentage: 85 },
        { name: "ทักษะในการไปกับบอล", percentage: 95 },
        { name: "เรียวแรงและความอึด", percentage: 80 },
      ],
      tabs: {
        th: [
          { type: "ประวัติ", title: "ข้อมูลส่วนตัว" },
          { type: "ประวัติ", title: "สโมสร/ทีมชาติ" },
          { type: "ประวัติ", title: "รางวัลส่วนตัว" },
          { type: "ประวัติ", title: "อาการบาดเจ็บ" },
          { type: "ประวัติ", title: "การโดนลงโทษ" },
          { type: "คลัง", title: "ภาพและวิดีโอ" },
        ],
        en: [
          { type: "Record", title: "Personal Information" },
          { type: "Record", title: "Team/Club" },
          { type: "Record", title: "Hall of Fame" },
          { type: "Record", title: "Injury" },
          { type: "Record", title: "Punishment" },
          { type: "", title: "Images & Videos" },
        ],
      },
      firstTab: {
        info: [
          {
            key: "dob",
            title: {
              en: "Date of Birth",
              th: "เกิดวันที่",
            },
            icon: "CalendarOutlined",

            props: { class: "fs-5 me-2" },
            value: {
              en: "-",
              th: "-",
            },
          },
          {
            key: "age",
            title: {
              en: "Age",
              th: "อายุ",
            },
            icon: "SatIcon",
            props: { name: "manCircle", cssClass: "me-2 fs-5" },
            value: {
              en: "-",
              th: "-",
            },
          },
          {
            key: "nationality",
            title: {
              en: "Nationality",
              th: "สัญชาติ",
            },
            icon: "SatIcon",
            props: { name: "idCard", cssClass: "me-2 fs-5" },
            value: {
              en: "-",
              th: "-",
            },
          },
          {
            key: "height",
            title: {
              en: "Height",
              th: "ส่วนสูง",
            },
            icon: "SatIcon",
            props: { name: "heightMan", cssClass: "me-2 fs-5" },
            value: {
              en: "-",
              th: "-",
            },
          },
          {
            key: "weight",
            title: {
              en: "Weight",
              th: "น้ำหนัก",
            },
            icon: "SatIcon",
            props: { name: "weightMachine", cssClass: "me-2 fs-5" },
            value: {
              en: "-",
              th: "-",
            },
          },
        ],
        content: {
          en: "",
          th: "",
        },
      },
      account_injuries: {},
    });
    const routes = ref<BreadcrumbRoute[]>([
      {
        path: "athletes",
        breadcrumbName: t("label.overview.athletes"),
      },
      {
        breadcrumbName:
          store.state.currentLanguage === "th"
            ? state.athlete.name.th ?? "-"
            : state.athlete.name.en ?? "test",
      },
    ]);
    const textCSS = (percentage: number) => {
      const { athlete } = toRefs(state);
      let css = "";
      if (percentage > 80) {
        css = "success";
      } else if (percentage > 60) {
        css = "warning-light";
      } else if (percentage > 30) {
        css = "warning";
      } else {
        css = "error";
      }

      return "text-" + css;
    };
    const injuryText = (level: string) => {
      switch (level) {
        case "MINOR":
          return {
            text: { th: "บาดเจ็บเล็กน้อย", en: "Minor" },
            css: "text-warning-light",
          };
        case "MODERATE":
          return {
            text: { th: "บาดเจ็บปานกลาง", en: "Moderate" },
            css: "text-warning",
          };
        case "SERIOUSLY":
          return {
            text: { th: "บาดเจ็บสาหัส", en: "Seriously" },
            css: "text-danger",
          };
        default:
          return { text: { th: "ไม่มี", en: "No injury" }, css: "text-normal" };
      }
    };
    const injuryBGCSS = (level: string) => {
      switch (level) {
        case "MINOR":
          return "bg-normal";
        case "MODERATE":
          return "bg-warning";
        case "SERIOUSLY":
          return "bg-danger";
        default:
          return "bg-normal";
      }
    };
    const EDurationUnit = (unit: string) => {
      switch (unit) {
        case "DAYS":
          return {
            en: "days",
            th: "วัน",
          };
        // return "วัน";
        case "WEEKS":
          return {
            en: "weeks",
            th: "สัปดาห์",
          };
        case "MONTHS":
          return {
            en: "months",
            th: "เดือน",
          };
        case "YEARS":
          return {
            en: "years",
            th: "ปี",
          };
        case "MATCHES":
          return {
            en: "matches",
            th: "แมทช์",
          };
        case "SEASONS":
          return {
            en: "seasons",
            th: "ฤดูกาล",
          };
        default:
          return {
            en: "",
            th: "",
          };
      }
    };
    return {
      t,
      ...toRefs(state),
      routes,
      store,
      activeKey: ref(0),
      textCSS,
      injuryText,
      injuryBGCSS,
      EDurationUnit,
      athleteId: route.params.id,
    };
  },
});
