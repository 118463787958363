<template>
  <div v-if="loading" class="loading-container">
    <LoadingOutlined />
  </div>
  <div v-else>
    <a-row>
      <a-col :xl="2" :lg="2" :md="0" :sm="0" :xs="0"></a-col>
      <a-col :xl="20" :lg="20" :md="24" :sm="24" :xs="24">
        <a-row>
          <a-col :xl="16" :lg="16">
            <div class="item-header">
              <img
                src="../../assets/icons/information_icon.svg"
                alt="information_icon"
              />
              <h5>{{ t("athletes.biography") }}</h5>
            </div>
            <div
              v-if="firstTab.content[store.state.currentLanguage]"
              v-html="firstTab.content[store.state.currentLanguage]"
            ></div>
            <div v-else>
              <!-- <a-empty /> -->
              {{ t("label.no_data") }}
            </div>
          </a-col>
          <a-col :xl="2" :lg="2" />
          <a-col :xl="6" :lg="6">
            <a-row>
              <a-col :xl="4" :lg="0" />
              <a-col :xl="20" :lg="24">
                <div class="item-header">
                  <h5>{{ t("athletes.contact_information") }}</h5>
                </div>
              </a-col>
            </a-row>
            <a-row class="mb-2">
              <a-col :xl="9" :md="9" :sm="12" :xs="8">
                <span>E-mail</span>
              </a-col>
              <a-col :xl="15" :md="15" :sm="12" :xs="15">
                <span class="contact">{{
                  information?.social_contacts?.account_social_contact_email ||
                  "-"
                }}</span>
              </a-col>
            </a-row>
            <a-row class="mb-2">
              <a-col :xl="9" :md="9" :sm="12" :xs="8">
                <span>Facebook</span>
              </a-col>
              <a-col :xl="15" :md="15" :sm="12" :xs="15">
                  <span class="contact">{{
                    information?.social_contacts
                      ?.account_social_contact_facebook || "-"
                  }}</span>
              </a-col>
            </a-row>
            <a-row class="mb-2">
              <a-col :xl="9" :md="9" :sm="12" :xs="8">
                <span>Instagram</span>
              </a-col>
              <a-col :xl="15" :md="15" :sm="12" :xs="15">
                <span class="contact">{{
                  information?.social_contacts
                    ?.account_social_contact_instagram || "-"
                }}</span>
              </a-col>
            </a-row>
            <a-row class="mb-2">
              <a-col :xl="9" :md="9" :sm="12" :xs="8">
                <span>Youtube</span>
              </a-col>
              <a-col :xl="15" :md="15" :sm="12" :xs="15">
                <span class="contact"> {{
                  information?.social_contacts
                    ?.account_social_contact_youtube || "-"
                }}</span>
              </a-col>
            </a-row>
            <a-row class="mb-2">
              <a-col :xl="9" :md="9" :sm="12" :xs="8">
                <span>twitter</span>
              </a-col>
              <a-col :xl="15" :md="15" :sm="12" :xs="15">
                <span class="contact">{{
                  information?.social_contacts
                    ?.account_social_contact_twitter || "-"
                }}</span>
              </a-col>
            </a-row>
            <a-row class="mb-2">
              <a-col :xl="9" :md="9" :sm="12" :xs="8">
                <span>Website</span>
              </a-col>
              <a-col :xl="15" :md="15" :sm="12" :xs="15">
                <span class="contact">{{
                  information?.social_contacts
                    ?.account_social_contact_website || "-"
                }}</span>
              </a-col>
            </a-row>
            <a-row class="mb-2">
              <a-col :xl="9" :md="9" :sm="12" :xs="8">
                <span>Fax</span>
              </a-col>
              <a-col :xl="15" :md="15" :sm="12" :xs="15">
                <span  class="contact">{{
                  information?.social_contacts?.account_social_contact_fax ||
                  "-"
                }}</span>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
    <a-row>
      <a-col :xl="2" />
      <a-col :xl="20">
        <a-divider />
      </a-col>
      <a-col :xl="2" />
    </a-row>
    <a-row>
      <a-col :xl="2" :lg="2" :md="0" :sm="0" :xs="0"></a-col>
      <a-col :xl="20" :lg="20" :md="24" :sm="24" :xs="24">
        <a-row>
          <a-col :xl="8" :lg="12" :md="12" :sm="12">
            <div class="item-header">
              <img
                src="../../assets/icons/heart_stethoscope_icon.svg"
                alt="information_icon"
              />
              <h5>{{ t("athletes.medical") }}</h5>
            </div>
            <p class="text-paragraph text-gray">{{ t("athletes.blood") }}</p>
            <div
              v-if="
                information?.account_informations
                  ?.account_information_blood_type
              "
            >
              <p>
                {{
                  information?.account_informations
                    .account_information_blood_type || "-"
                }}
              </p>
            </div>
            <div v-else>
              <p>-</p>
            </div>
            <p class="text-paragraph text-gray">
              {{ t("athletes.congential") }}
            </p>
            <div
              v-if="
                information?.midical_conditions &&
                information?.midical_conditions.length > 0 &&
                information?.midical_conditions.filter(
                  (midical) =>
                    midical.account_medical_condition_type ===
                    'CONGENTIAL_DISEASE'
                )?.length > 0
              "
            >
              <div
                v-for="midical in information?.midical_conditions"
                :key="midical.id"
              >
                <p
                  v-if="
                    midical.account_medical_condition_type ===
                    'CONGENTIAL_DISEASE'
                  "
                  class="text-paragraph"
                >
                  <label v-if="store.state.currentLanguage === 'th'">{{
                    midical.account_medical_condition_name_th || "-"
                  }}</label>
                  <label v-else>{{
                    midical.account_medical_condition_name_en || "-"
                  }}</label>
                </p>
              </div>
            </div>
            <div v-else>
              <p>-</p>
            </div>
            <p class="text-paragraph text-gray">{{ t("athletes.drug") }}</p>
            <div
              v-if="
                information?.midical_conditions &&
                information?.midical_conditions.length > 0 &&
                information?.midical_conditions.filter(
                  (midical) =>
                    midical.account_medical_condition_type === 'DRUG_ALLERGY'
                )?.length > 0
              "
            >
              <div
                v-for="midical in information?.midical_conditions"
                :key="midical.id"
              >
                <p
                  v-if="
                    midical.account_medical_condition_type === 'DRUG_ALLERGY'
                  "
                  class="text-paragraph"
                >
                  <label v-if="store.state.currentLanguage === 'th'">{{
                    midical.account_medical_condition_name_th || "-"
                  }}</label>
                  <label v-else>{{
                    midical.account_medical_condition_name_en || "-"
                  }}</label>
                </p>
              </div>
            </div>
            <p v-else>-</p>
            <p class="text-paragraph text-gray">{{ t("athletes.food") }}</p>
            <div
              v-if="
                information?.midical_conditions &&
                information?.midical_conditions.length > 0 &&
                information?.midical_conditions.filter(
                  (midical) =>
                    midical.account_medical_condition_type === 'FOOD_ALLERGY'
                )?.length > 0
              "
            >
              <div
                v-for="midical in information?.midical_conditions"
                :key="midical.id"
              >
                <p
                  v-if="
                    midical.account_medical_condition_type === 'FOOD_ALLERGY'
                  "
                  class="text-paragraph"
                >
                  <label v-if="store.state.currentLanguage === 'th'">{{
                    midical.account_medical_condition_name_th || "-"
                  }}</label>
                  <label v-else>{{
                    midical.account_medical_condition_name_en || "-"
                  }}</label>
                </p>
              </div>
            </div>
            <p v-else>-</p>
          </a-col>
          <a-col :xl="16" :lg="12" :md="12" :sm="12">
            <div class="item-header">
              <img
                src="../../assets/icons/education_icon.svg"
                alt="education_icon"
              />
              <h5>{{ t("athletes.education") }}</h5>
            </div>
            <div
              v-if="
                information?.account_education &&
                information?.account_education.length > 0
              "
            >
              <div
                v-for="education in information?.account_education"
                :key="education.id"
              >
                <p
                  class="text-gray"
                  v-if="store.state.currentLanguage === 'th'"
                >
                  {{
                    education?.global_config_educations
                      ?.global_config_education_name_th
                  }}
                </p>

                <p class="text-gray" v-else>
                  {{
                    education?.global_config_educations
                      ?.global_config_education_name_en
                  }}
                </p>
                <p v-if="store.state.currentLanguage === 'th'">
                  {{
                    `${education?.account_education_faculty} ${
                      education?.account_education_name_th
                    } ${+education?.account_education_end_at + 543}`
                  }}
                </p>
                <p v-else>
                  {{
                    `${education?.account_education_faculty_en} ${
                      education?.account_education_name_en
                    } ${+education?.account_education_end_at}`
                  }}
                </p>
              </div>
            </div>
            <div v-else>{{ t("label.no_data") }}</div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { watch, onMounted, onUpdated, reactive, toRefs } from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { useRequest } from "vue-request";
import { athleteService } from "@/_services";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  name: "Tab1",
  components: {
    LoadingOutlined,
  },
  props: {
    firstTab: Object,
    athleteId: Number,
  },
  setup(props) {
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();

    const { firstTab, athleteId } = props;
    const { run: fetchAthleteInformation } = useRequest(
      athleteService.getInformation,
      {
        manual: true,
        onSuccess: (formattedResult) => {
          state.information = formattedResult.data.data.account;
        },
      }
    );

    onMounted(async () => {
      await fetchAthleteInformation(+athleteId);
      state.loading = false;
    });

    const state = reactive({
      loading: true,
      information: {},
    });

    // fetchAthleteInformation(+athleteId)
    return {
      t,
      store,
      firstTab,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.loading-container {
  text-align: center;
}
.contact{
    overflow-wrap: break-word;
}
.item-header {
  display: flex;
  align-items: center;
  padding: 10px 0px;

  h5 {
    margin: auto 5px;
  }

  img {
    width: 25px;
  }
}

.text-paragraph {
  word-wrap: break-word;
}

.text-gray {
  color: #92929d;
}
.tab-details-mobile {
  display: none !important;
}
@media (max-width: 768px) {
  .data-tab {
    font-size: 14px;
    margin-left: 20px;
  }
  .tab-details {
    display: none !important;
  }
  .tab-details-mobile {
    display: block !important;
  }
}
</style>
