import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-122521e6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row py-3" }
const _hoisted_2 = { class: "container-table" }
const _hoisted_3 = { class: "d-flex align-items-center mb-3" }
const _hoisted_4 = { class: "m-0" }
const _hoisted_5 = { style: { overflowY: 'auto' } }
const _hoisted_6 = { class: "pagination-container" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "row py-3" }
const _hoisted_10 = { class: "container-table" }
const _hoisted_11 = { class: "d-flex align-items-center mb-3" }
const _hoisted_12 = { class: "m-0" }
const _hoisted_13 = { style: { overflowY: 'auto' } }
const _hoisted_14 = { class: "pagination-container" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_pagination = _resolveComponent("a-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.t("athletes.penalty")), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_a_table, {
            class: "team-table",
            size: "middle",
            columns: _ctx.store.state.currentLanguage === 'th' ? _ctx.columns : _ctx.columnsEN,
            "data-source": _ctx.dataSourcePenaliesCurrent.data,
            pagination: false,
            bordered: false
          }, null, 8, ["columns", "data-source"])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", null, _toDisplayString(`1-${_ctx.dataSourcePenaliesCurrent?.perPage} ${_ctx.t("label.table.of")} ${
              _ctx.dataSourcePenaliesCurrent?.total
            } ${_ctx.t("label.table.items")}`), 1),
          _createVNode(_component_a_pagination, {
            current: _ctx.currentPagePenaltiesCurrent,
            "page-size-options": _ctx.pageSizeOptions,
            total: _ctx.dataSourcePenaliesCurrent?.total,
            "show-size-changer": "",
            "page-size": _ctx.dataSourcePenaliesCurrent.perPage,
            onChange: _ctx.onPageChangePenaltiesCurrent,
            onShowSizeChange: _ctx.onShowSizeChangePenaltiesCurrent
          }, {
            buildOptionText: _withCtx((props) => [
              (props.value !== _ctx.dataSourcePenaliesCurrent?.perPage)
                ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(props.value), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_8, "ทั้งหมด"))
            ]),
            _: 1
          }, 8, ["current", "page-size-options", "total", "page-size", "onChange", "onShowSizeChange"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("h5", _hoisted_12, _toDisplayString(_ctx.t("athletes.penalty_history")), 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createVNode(_component_a_table, {
            class: "team-table",
            size: "middle",
            columns: _ctx.store.state.currentLanguage === 'th' ? _ctx.columns : _ctx.columnsEN,
            "data-source": _ctx.dataSourcePenalies.data,
            pagination: false,
            bordered: false
          }, null, 8, ["columns", "data-source"])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("p", null, _toDisplayString(`1-${_ctx.dataSourcePenalies?.perPage} ${_ctx.t("label.table.of")} ${
              _ctx.dataSourcePenalies?.total
            } ${_ctx.t("label.table.items")}`), 1),
          _createVNode(_component_a_pagination, {
            current: _ctx.currentPagePenalties,
            "page-size-options": _ctx.pageSizeOptions,
            total: _ctx.dataSourcePenalies?.total,
            "show-size-changer": "",
            "page-size": _ctx.dataSourcePenalies.perPage,
            onChange: _ctx.onPageChangePenalties,
            onShowSizeChange: _ctx.onShowSizeChangePenalties
          }, {
            buildOptionText: _withCtx((props) => [
              (props.value !== _ctx.dataSourcePenalies?.perPage)
                ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(props.value), 1))
                : (_openBlock(), _createElementBlock("span", _hoisted_16, "ทั้งหมด"))
            ]),
            _: 1
          }, 8, ["current", "page-size-options", "total", "page-size", "onChange", "onShowSizeChange"])
        ])
      ])
    ])
  ], 64))
}