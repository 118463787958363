<template>
  <div v-if="loading" class="loading-container">
    <LoadingOutlined />
  </div>
  <div v-else>
    <div class="container-table">
      <div class="item-header">
        <img src="../../assets/icons/cub_icon.svg" alt="flag_icon" />
        <h5>{{ t("athletes.nation_history") }}</h5>
      </div>
      <div :style="{ overflowY: 'auto' }">
        <a-table
          class="team-table"
          size="middle"
          :columns="columns"
          :data-source="hornorNation.data"
          :pagination="false"
          :bordered="false"
        />
      </div>

      <div class="pagination-container">
        <p>
          {{
            `1-${hornorNation?.perPage} ${t("label.table.of")} ${
              hornorNation?.total
            } ${t("label.table.items")}`
          }}
        </p>
        <a-pagination
          :current="currentPageHornorNation"
          :page-size-options="pageSizeOptionsHornorNation"
          :total="hornorNation?.total"
          show-size-changer
          :page-size="hornorNation.perPage"
          @change="onPageChangeHornorNation"
          @showSizeChange="onShowSizeChangeHornorNation"
        >
          <template #buildOptionText="props">
            <span v-if="props.value !== hornorNation?.perPage">{{
              props.value
            }}</span>
            <span v-else>ทั้งหมด</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <div class="container-table">
      <div class="item-header">
        <img src="../../assets/icons/hornor_team_icon.svg" alt="flag_icon" />
        <h5>{{ t("athletes.club_honor") }}</h5>
      </div>
      <div :style="{ overflowY: 'auto' }">
        <a-table
          class="team-table"
          size="middle"
          :columns="columns"
          :data-source="hornorClub.data"
          :pagination="false"
          :bordered="false"
        />
      </div>

      <div class="pagination-container">
        <p>
          {{
            `1-${hornorClub?.perPage} ${t("label.table.of")} ${
              hornorClub?.total
            } ${t("label.table.items")}`
          }}
        </p>
        <a-pagination
          :current="currentPageHornorClub"
          :page-size-options="pageSizeOptionsHornorNation"
          :total="hornorClub?.total"
          show-size-changer
          :page-size="hornorClub.perPage"
          @change="onPageChangeHornorClub"
          @showSizeChange="onShowSizeChangeHornorClub"
        >
          <template #buildOptionText="props">
            <span v-if="props.value !== hornorClub?.perPage">{{
              props.value
            }}</span>
            <span v-else>ทั้งหมด</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <div class="container-table">
      <div class="item-header">
        <img src="../../assets/icons/honor_icon.svg" alt="flag_icon" />
        <h5>{{ t("athletes.personal_award") }}</h5>
      </div>
      <div :style="{ overflowY: 'auto' }">
        <a-table
          class="team-table"
          size="middle"
          :columns="columns"
          :data-source="hornorPersonal.data"
          :pagination="false"
          :bordered="false"
        />
      </div>

      <div class="pagination-container">
        <p>
          {{
            `1-${hornorPersonal?.perPage} ${t("label.table.of")} ${
              hornorPersonal?.total
            } ${t("label.table.items")}`
          }}
        </p>
        <a-pagination
          :current="currentPageHornorPersonal"
          :page-size-options="pageSizeOptionsHornorNation"
          :total="hornorPersonal?.total"
          show-size-changer
          :page-size="hornorPersonal.perPage"
          @change="onPageChangeHornorPersonal"
          @showSizeChange="onShowSizeChangeHornorPersonal"
        >
          <template #buildOptionText="props">
            <span v-if="props.value !== hornorPersonal?.perPage">{{
              props.value
            }}</span>
            <span v-else>ทั้งหมด</span>
          </template>
        </a-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, watchEffect, watch } from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { useRequest } from "vue-request";
import { athleteService } from "@/_services";
import RewardGrayIcon from "../../assets/reward-placeholder-gray.svg";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default {
  components: {
    LoadingOutlined,
    RewardGrayIcon,
  },
  props: {
    athleteId: Number,
  },
  setup(props) {
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();
    const { athleteId } = props;
    const { run: fetchHonorsNation } = useRequest(
      athleteService.getHonorsNation,
      {
        manual: true,
        onSuccess: (formattedResult) => {
          state.hornorNation = formattedResult.data;
        },
      }
    );

    const { run: fetchHonorsClub } = useRequest(athleteService.getHonorsClub, {
      manual: true,
      onSuccess: (formattedResult) => {
        state.hornorClub = formattedResult.data;
      },
    });

    const { run: fetchHonorsPersonal } = useRequest(
      athleteService.getHonorsPersonal,
      {
        manual: true,
        onSuccess: (formattedResult) => {
          state.hornorPersonal = formattedResult.data;
        },
      }
    );

    onMounted(async () => {
      try {
        await onFetchHornorNation({
          size: state.pageSizeOptionsHornorNation[0],
          page: state.currentPageHornorNation,
        });
        await onFetchHornorClub({
          size: state.pageSizeOptionsHornorNation[0],
          page: state.currentPageHornorClub,
        });
        await onFetchHornorPersonal({
          size: state.pageSizeOptionsHornorNation[0],
          page: state.currentPageHornorPersonal,
        });
      } catch (error) {
        // console.log("Tab3 fetch :: error ", error);
      } finally {
        state.loading = false;
      }
    });

    const onFetchHornorNation = async (params) => {
      await fetchHonorsNation(athleteId, params);
    };

    const onFetchHornorClub = async (params) => {
      await fetchHonorsClub(athleteId, params);
    };

    const onFetchHornorPersonal = async (params) => {
      await fetchHonorsPersonal(athleteId, params);
    };
    const state = reactive({
      columns: [
        {
          key: "icon",
          title: "",
          dataIndex: "icon",
          align: "center",
          customRender: ({ text, record, index }) => {
            return (
              <div>
                <img
                  src={RewardGrayIcon}
                  alt="icon"
                  style={{ width: "30px" }}
                />
              </div>
            );
          },
          width: "60px",
        },
        {
          key: "setting_sub_competition_name_th",
          title: "ชื่อรายการแข่งขัน",
          dataIndex: "setting_sub_competition_name_th",
          customRender: ({ text, record, index }) => {
            if (store.state.currentLanguage === "th") {
              return record.setting_sub_competition_name_th;
            }
            return record.setting_sub_competition_name_en;
          },
          width: "300px",
        },
        {
          key: "setting_team_name",
          title: "ทีมชาติชุด",
          dataIndex: "setting_team_name",
          customRender: ({ text, record, index }) => {
            if (store.state.currentLanguage === "th") {
              return record.setting_team_name_th;
            }
            return record.setting_team_name_en;
          },
          width: "300px",
        },
        {
          key: "honor_name",
          title: "ชื่อรางวัล",
          dataIndex: "honor_name",
          customRender: ({ text, record, index }) => {
            if (store.state.currentLanguage === "th") {
              return record.honor_name;
            }
            return record.honor_name_en;
          },
        },
        {
          key: "honor_year",
          title: "ปีที่ได้รับ",
          dataIndex: "honor_year",
        },
      ],
      loading: true,
      hornorNation: {},
      hornorClub: {},
      hornorPersonal: {},
      currentPageHornorNation: 1,
      currentPageHornorClub: 1,
      currentPageHornorPersonal: 1,
      pageSizeOptionsHornorNation: ["10", "20", "50", "100"],
    });

    const onPageChangeHornorNation = (page, pageSize) => {
      state.currentPageHornorNation = page;
      onFetchHornorNation({ page, size: pageSize });
    };
    const onShowSizeChangeHornorNation = (current, pageSize) => {
      state.currentPageHornorNation = current;
      onFetchHornorNation({ page: current, size: pageSize });
    };
    const onPageChangeHornorClub = (page, pageSize) => {
      state.currentPageHornorClub = page;
      onFetchHornorClub({ page, size: pageSize });
    };
    const onShowSizeChangeHornorClub = (current, pageSize) => {
      state.currentPageHornorClub = current;
      onFetchHornorClub({ page: current, size: pageSize });
    };
    const onPageChangeHornorPersonal = (page, pageSize) => {
      state.currentPageHornorPersonal = page;
      onFetchHornorPersonal({ page, size: pageSize });
    };
    const onShowSizeChangeHornorPersonal = (current, pageSize) => {
      state.currentPageHornorPersonal = current;
      onFetchHornorPersonal({ page: current, size: pageSize });
    };

    // watchEffect(() => {
    //   console.log("watchEffect :: ", store.state.currentLanguage, state);
    // });

    watch(
      () => store.state.currentLanguage,
      (currentLanguage, prevCurrentLanguage) => {
        /* ... */

        if (currentLanguage === "th") {
          state.columns[1].title = "ชื่อรายการแข่งขัน";
          state.columns[2].title = "ทีมชาติชุด";
          state.columns[3].title = "ชื่อรางวัล";
          state.columns[4].title = "ปีที่ได้รับ";
        } else {
          state.columns[1].title = "Name of Competition";
          state.columns[2].title = "National Team Level";
          state.columns[3].title = "Award name";
          state.columns[4].title = "Year";
        }
      }
    );

    return {
      t,
      store,
      ...toRefs(state),
      onPageChangeHornorNation,
      onShowSizeChangeHornorNation,
      onPageChangeHornorClub,
      onShowSizeChangeHornorClub,
      onPageChangeHornorPersonal,
      onShowSizeChangeHornorPersonal,
    };
  },
};
</script>

<style lang="less" scoped>
.item-header {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  h5 {
    margin: auto 5px;
  }
  img {
    width: 25px;
  }
}
.loading-container {
  text-align: center;
}
.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  p {
    margin: auto 0px;
  }
}
</style>