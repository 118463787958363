import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0721ed26"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "text-white"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_breadcrumb = _resolveComponent("a-breadcrumb")!

  return (_openBlock(), _createBlock(_component_a_breadcrumb, {
    separator: ">",
    routes: _ctx.routes,
    class: "position-relative",
    style: { zIndex: 9 }
  }, {
    itemRender: _withCtx(({ route, routes, paths }) => [
      (routes.indexOf(route) === routes.length - 1)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(route.breadcrumbName), 1))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createVNode(_component_router_link, {
              to: `/${paths.join('/')}`
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(route.breadcrumbName), 1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]))
    ]),
    _: 1
  }, 8, ["routes"]))
}