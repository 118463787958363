
import { defineComponent, reactive, toRefs, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default defineComponent({
  props: {
    firstTab: {
      type: Object,
    },
    staff: {
      type: Object,
    },
  },
  setup(props) {
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();

    return {
      t,
      store,
    };
  },
});
