<template>
  <div v-if="loading" class="loading-container">
    <LoadingOutlined />
  </div>
  <div v-else>
    <div class="injuries-status-container">
      <div class="item-header">
        <img
          src="../../assets/icons/sharpicons_doctor_bag.svg"
          alt="flag_icon"
        />
        <h5 class="text-white">{{ t("athletes.status_injury") }}</h5>
      </div>
      <a-divider class="divider" />
      <div>
        <a-row>
          <a-col :span="window.innerWidth < 768 ? '12':window.innerWidth < 1300? '8':'4'" :class="window.innerWidth < 768?'mb-3':''">
            <div class="text-container">
              <p>{{ t("athletes.date_injury") }}</p>
              <p class="bold">
                {{
                  injuriesCurrentList[0]?.account_injury_date
                    ? DateTime.fromISO(
                        injuriesCurrentList[0]?.account_injury_date
                      ).toFormat("d MMM yyyy")
                    : "-"
                }}
              </p>
            </div>
          </a-col>
          <a-col :span="window.innerWidth < 768 ? '12':window.innerWidth < 1300? '8':'4'" :class="window.innerWidth < 768?'mb-3':''">
            <div class="text-container">
              <p>{{ t("athletes.injury2") }}</p>
              <p class="bold" v-if="store.state.currentLanguage === 'th'">
                {{
                  injuriesCurrentList[0]?.account_injury_name_th
                    ? injuriesCurrentList[0]?.account_injury_name_th
                    : "-"
                }}
              </p>
              <p class="bold" v-else>
                {{
                  injuriesCurrentList[0]?.account_injury_name_en
                    ? injuriesCurrentList[0]?.account_injury_name_en
                    : "-"
                }}
              </p>
            </div>
          </a-col>
          <a-col :span="window.innerWidth < 768 ? '12':window.innerWidth < 1300? '8':'4'" :class="window.innerWidth < 768?'mb-3':''">
            <div class="text-container">
              <p>{{ t("athletes.level_injury") }}</p>
              <p class="bold" v-if="store.state.currentLanguage === 'th'">
                {{
                  injuriesCurrentList[0]?.account_injury_level
                    ? Helpers.injuriesLevel(
                        injuriesCurrentList[0]?.account_injury_level
                      ).fullTH
                    : "-"
                }}
              </p>
              <p class="bold" v-else>
                {{
                  injuriesCurrentList[0]?.account_injury_level
                    ? Helpers.injuriesLevel(
                        injuriesCurrentList[0]?.account_injury_level
                      ).fullEN
                    : "-"
                }}
              </p>
            </div>
          </a-col>
          <a-col :span="window.innerWidth < 768 ? '12':window.innerWidth < 1300? '8':'4'" :class="window.innerWidth < 768?'mb-3':''">
            <div class="text-container">
              <p>{{ t("athletes.group_injury") }}</p>
              <p class="bold" v-if="store.state.currentLanguage === 'th'">
                {{
                  injuriesCurrentList[0]?.global_config_injury_types
                    ?.global_config_injury_type_name_th
                    ? injuriesCurrentList[0]?.global_config_injury_types
                        ?.global_config_injury_type_name_th
                    : "-"
                }}
              </p>
              <p class="bold" v-else>
                {{
                  injuriesCurrentList[0]?.global_config_injury_types
                    ?.global_config_injury_type_name_en
                    ? injuriesCurrentList[0]?.global_config_injury_types
                        ?.global_config_injury_type_name_en
                    : "-"
                }}
              </p>
            </div>
          </a-col>
          <a-col :span="window.innerWidth < 768 ? '12':window.innerWidth < 1300? '8':'4'" :class="window.innerWidth < 768?'mb-3':''">
            <div class="text-container">
              <p>{{ t("athletes.recovery") }}</p>
              <p class="bold">
                {{
                  injuriesCurrentList[0]?.account_injury_recovery_time
                    ? `${
                        injuriesCurrentList[0]?.account_injury_recovery_time
                      } ${
                        Helpers.EDurationUnit(
                          injuriesCurrentList[0]
                            ?.account_injury_recovery_time_unit
                        )[store.state.currentLanguage]
                      }`
                    : "-"
                }}
              </p>
            </div>
          </a-col>
          <a-col :span="window.innerWidth < 768 ? '12':window.innerWidth < 1300? '8':'4'" :class="window.innerWidth < 768?'mb-3':''">
            <div class="text-container">
              <p>{{ t("athletes.cause_injury") }}</p>
              <p class="bold">
                {{Helpers.CauseUnit(injuriesCurrentList[0]?.account_injury_cause)[store.state.currentLanguage]}}
              </p>

            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="container-table">
      <div class="item-header">
        <img src="../../assets/icons/report_icon.svg" alt="flag_icon" />
        <h5>{{ t("athletes.history_injury") }}</h5>
      </div>
      <div v-if="injuriesList.length === 0">{{ t("label.no_data") }}</div>
      <a-row v-else :gutter="[24, 24]">
        <a-col :span="window.innerWidth < 768 ? '24':window.innerWidth < 1300? '12':'6'" v-for="item in injuriesList" :key="item?.id">
          <a-card
            :headStyle="{
              background: Helpers.injuriesLevel(item?.account_injury_level)
                .color,
            }"
            style="position: relative"
          >
            <template v-slot:title>
              <a-row>
                <a-col :span="11" class="text-left text-white">
                  <p class="card-text">{{ t("athletes.level") }}</p>
                </a-col>
                <a-col :span="13" class="text-right text-white">
                  <p v-if="item?.account_injury_level" class="card-text bold">
                    {{
                      store.state.currentLanguage === "th"
                        ? Helpers.injuriesLevel(item?.account_injury_level).th
                        : Helpers.injuriesLevel(item?.account_injury_level).en
                    }}
                  </p>
                  <p v-else>-</p>
                </a-col>
              </a-row>
            </template>
            <a-row class="card-body-row">
              <a-col :span="11" class="text-left">
                <p>{{ t("athletes.symptom") }}</p>
              </a-col>
              <a-col :span="13" class="text-right">
                <p class="bold" v-if="store.state.currentLanguage === 'th'">
                  {{ item?.account_injury_name_th || "-" }}
                </p>
                <p class="bold" v-else>
                  {{ item?.account_injury_name_en || "-" }}
                </p>
              </a-col>
            </a-row>
            <a-row class="card-body-row">
              <a-col :span="11" class="text-left">
                <p>{{ t("athletes.group_symptom") }}</p>
              </a-col>
              <a-col :span="13" class="text-right">
                <p class="bold">
                  <a-typography-paragraph
                    v-if="store.state.currentLanguage === 'th'"
                    class="paragraph"
                    :ellipsis="{
                      tooltip: item?.global_config_injury_types
                        ?.global_config_injury_type_name_th
                        ? item?.global_config_injury_types
                            ?.global_config_injury_type_name_th
                        : '-',
                      rows: 1,
                    }"
                    :content="
                      item?.global_config_injury_types
                        ?.global_config_injury_type_name_th
                        ? item?.global_config_injury_types
                            ?.global_config_injury_type_name_th
                        : '-'
                    "
                  >
                  </a-typography-paragraph>
                  <a-typography-paragraph
                    v-else
                    class="paragraph"
                    :ellipsis="{
                      tooltip: item?.global_config_injury_types
                        ?.global_config_injury_type_name_en
                        ? item?.global_config_injury_types
                            ?.global_config_injury_type_name_en
                        : '-',
                      rows: 1,
                    }"
                    :content="
                      item?.global_config_injury_types
                        ?.global_config_injury_type_name_en
                        ? item?.global_config_injury_types
                            ?.global_config_injury_type_name_en
                        : '-'
                    "
                  >
                  </a-typography-paragraph>
                </p>
              </a-col>
            </a-row>
            <a-row class="card-body-row">
              <a-col :span="11" class="text-left">
                <p>{{ t("athletes.cause") }}</p>
              </a-col>
              <a-col :span="13" class="text-right">
                <a-typography-paragraph
                  v-if="store.state.currentLanguage === 'th'"
                  class="bold paragraph"
                  ellipsisTooltip
                  :ellipsis="{ tooltip: item?.account_injury_remark, rows: 4 }"
                  :content="item?.account_injury_remark || '-'"
                >
                  <!-- <template #ellipsisTooltip>
                    <p>{{ item?.account_injury_remark }}</p>
                  </template> -->
                </a-typography-paragraph>
                <a-typography-paragraph
                  v-else
                  class="bold paragraph"
                  ellipsisTooltip
                  :ellipsis="{
                    tooltip: item?.account_injury_remark_en,
                    rows: 4,
                  }"
                  :content="item?.account_injury_remark_en || '-'"
                >
                  <!-- <template #ellipsisTooltip>
                    <p>{{ item?.account_injury_remark }}</p>
                  </template> -->
                </a-typography-paragraph>
                <!-- <p class="bold">{{ item?.account_injury_remark || "-" }}</p> -->
              </a-col>
            </a-row>
            <a-row class="card-body-row">
              <a-col :span="11" class="text-left">
                <p>{{ t("athletes.recovery") }}</p>
              </a-col>
              <a-col :span="13" class="text-right">
                <p class="bold">
                  {{
                    item?.account_injury_recovery_time
                      ? `${item?.account_injury_recovery_time} ${
                          Helpers.EDurationUnit(
                            item?.account_injury_recovery_time_unit
                          )[store.state.currentLanguage]
                        }`
                      : "-"
                  }}
                </p>
              </a-col>
            </a-row>
            <div style="position: absolute; bottom: 0px; width: 84%">
              <a-divider class="divider" />
              <a-row class="card-footer-style" style="">
                <a-col :span="12" class="text-right">{{
                  t("athletes.date_injury")
                }}</a-col>
                <a-col :span="12" class="text-left"
                  >{{
                    item?.account_injury_date
                      ? DateTime.fromISO(item?.account_injury_date).toFormat(
                          "dd/MM/yyyy"
                        )
                      : "-"
                  }}
                </a-col>
              </a-row>
            </div>
          </a-card>
        </a-col>
      </a-row>
      <div class="load-more-container">
        <div v-if="loadingLoadMore" class="loading-container">
          <LoadingOutlined />
        </div>
        <a-button
          v-else-if="injuriesCurrentList?.length >= injuriesSize"
          type="link"
          :onClick="onLoadMore"
        >
          <img
            src="../../assets/icons/arrow_down_icon.svg"
            :style="{ width: '32px' }"
            alt="arrow-down"
          />
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import { useRequest } from "vue-request";
import { athleteService } from "@/_services";
import { Helpers } from "@/_modules/helpers";
import { DateTime } from "luxon";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
export default {
  components: {
    LoadingOutlined,
  },
  props: {
    athleteId: Number,
  },
  setup(props) {
    const { athleteId } = props;
    const { t } = useI18n({
      useScope: "global",
    });
    const store = useStore();

    const { run: fetchInjuriesCurrent } = useRequest(
      athleteService.getInjuriesCurrent,
      {
        manual: true,
        onSuccess: (formattedResult) => {
          state.injuriesCurrent = formattedResult.data;
          state.injuriesCurrentList = [
            ...state.injuriesCurrentList,
            ...formattedResult.data.data,
          ];
        },
      }
    );
    const { run: fetchInjuries } = useRequest(athleteService.getInjuries, {
      manual: true,
      onSuccess: (formattedResult) => {
        state.injuries = formattedResult.data;
        state.injuriesList = [
          ...state.injuriesList,
          ...formattedResult.data.data,
        ];
      },
    });
    onMounted(async () => {
      try {
        await onFetchInjuriesCurrent({
          size: state.injuriesSize,
          page: state.injuriesPage,
        });
        await onFetchInjuries({});
      } catch (error) {
        // console.log("Tab4 fetch :: error ", error);
      } finally {
        state.loading = false;
      }
    });

    const onFetchInjuriesCurrent = (params) => {
      fetchInjuriesCurrent(athleteId, params);
    };

    const onFetchInjuries = (params) => {
      fetchInjuries(athleteId, params);
    };

    const onLoadMore = async () => {
      state.loadingLoadMore = true;
      state.injuriesPage += 1;
      await onFetchInjuries({
        page: state.injuriesPage,
        size: state.injuriesSize,
      });
      setTimeout(() => {
        state.loadingLoadMore = false;
      }, 1000);
    };

    const state = reactive({
      injuriesCurrent: {},
      injuriesCurrentList: [],
      injuries: {},
      injuriesList: [],
      loading: true,
      loadingLoadMore: false,
      injuriesSize: 8,
      injuriesPage: 1,
    });

    return {
      t,
      store,
      ...toRefs(state),
      Helpers,
      DateTime,
      onLoadMore,
    };
  },
};
</script>

<style lang="less" scoped>
p {
  margin: auto 0px;
}
.paragraph {
  margin: auto 0px;
}
.item-header {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  h5 {
    margin: auto 5px;
  }
  .text-white {
    color: white;
  }
  img {
    width: 25px;
  }
}
.item-body {
  height: 265px;
}
.injuries-status-container {
  border-radius: 20px;
  background: #6e6b7b;
  padding: 20px;
  margin-bottom: 30px;
}
.divider {
  margin: 6px 0;
  background: white;
}
.text-container {
  color: white;
  text-align: center;
  p {
    margin: 5px 0px;
  }
}
.bold {
  font-weight: 600;
}
:deep(.sat-card-bordered) {
  box-shadow: 0px 25px 50px rgba(15, 76, 130, 0.25);
}
:deep(.sat-card-head) {
  min-height: 30px;
}
:deep(.sat-card-head-title) {
  padding: 8px 0;
}
:deep(.sat-card-body) {
  padding: 8px 24px;
  min-height: 200px;
  max-height: 265px;
}
.text-left {
  text-align: right;
  p {
    padding-right: 5px;
  }
}
.text-right {
  text-align: left;
  p {
    padding-left: 5px;
  }
}
.card-text {
  font-size: 14px;
}
.card-footer-style {
  padding: 8px 0px;
  color: #267fff;
}
.card-body-row {
  margin: 10px 0px;
  // height: 250px;
}
.loading-container {
  text-align: center;
}
.text-white {
  color: white;
}
.load-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
@media (max-width: 768px) {
    .injury-cuurent{
     grid-column: 2;
    }
}
</style>